/* .timesheet-table  {border-collapse:collapse;border-spacing:0;margin:auto; width:75vw} */
.timesheet-table td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.timesheet-table th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.timesheet-table .tg-1wig{font-weight:bold;text-align:left;vertical-align:top}
.timesheet-table .tg-bobw{font-weight:bold;text-align:center;vertical-align:bottom}
.timesheet-table .tg-baqh{text-align:center;vertical-align:top}
.timesheet-table .tg-6ic8{font-weight:bold;text-align:right;vertical-align:top}
.timesheet-table .tg-0lax{text-align:left;vertical-align:top}
.timesheet-table .tg-amwm{font-weight:bold;text-align:center;vertical-align:top}
.timesheet-table .tg-0pky{text-align:left;vertical-align:top}
.error{
  border: 3px solid red !important;
}
.valid{
  border: 3px solid green !important;
}
/* Dropdown Button */
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #3e8e41;
}

/* The search field */
/* #myInput {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
  flex: 1
} */

.close-search-dropdown{
  flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {outline: 3px solid #ddd;}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  /* display: none; */
  background-color: #f6f6f6;
  min-width: 230px;
  border: 1px solid #ddd;
  z-index: 1;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {background-color: #f1f1f1}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}


/* .timesheet-table {
  border-collapse: collapse;
  width: 100%;
} */

.timesheet-table td, .timesheet-table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.timesheet-table th {
  background-color: #f2f2f2;
}

.timesheet-table input, .timesheet-table select, .timesheet-table textarea, #myInput {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.timesheet-table select{
  padding-right: 2.5rem;
}

.timesheet-table .table-data-rows:nth-child(even) {
  background-color: #f2f2f2;
}
